// export const paymentDetails={
//     gpay:"fcbizopiskn@freecharge",
//     paytm:"fcbizopiskn@freecharge"
// }
// Batham

// export const paymentDetails={
//     gpay:"7797301257@okbizaxis",
//     paytm:"7797301257@okbizaxis"
// }

export const paymentDetails={
    gpay:"9643815627@okbizaxis",
    paytm:"9643815627@okbizaxis"
}